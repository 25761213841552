const globalObj = {};
//存
globalObj.set = function(key, val) {
  window.localStorage.setItem(key, JSON.stringify(val));
};
//取
globalObj.get = function(key) {
  let val = JSON.parse(window.localStorage.getItem(key));
  return val;
};
export default { globalObj };
