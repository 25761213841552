import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "Index",
    component: () =>
    import( "../views/Index.vue"),
  },
  {
    path: "/background",
    name: "Background",
    component: () =>
    import( "../views/Background.vue"),
  },
  {
    path: "/host-application",
    name: "Application",
    component: () =>
    import( "../views/host-application.vue"),
  },
  {
    path: "/homestay",
    name: "Homestay",
    component: () =>
      import( "../views/Homestay.vue"),
  },
  {
    path: "/w9",
    name: "W9",
    component: () =>
      import( "../views/w9.vue"),
  },
  {
    path: "/pdf",
    name: "Pdf",
    component: () =>
      import( "../views/Pdf.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
