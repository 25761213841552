import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from 'element-ui';
import locale from '../node_modules/element-ui/lib/locale/lang/en';
import Storage from "@/plugin/storage.js"; //全局Stroage存取操作方法
import vueEsign from 'vue-esign' //签名插件
import '@/mixins/index.js'
import 'element-ui/lib/theme-chalk/index.css'
import "@/styles/variables.scss"
import "@/permission.js"
import "normalize.css"
Vue.use(ElementUI,{locale});
Vue.use(vueEsign);
Vue.prototype.$Storage = Storage;
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
